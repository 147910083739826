import React from "react";
import { Box, Grid, Hidden } from "@material-ui/core";
import WrapTypo from "../components/WrapTypo";
import { useStaticQuery, graphql, Link } from "gatsby";
import Head from "../components/head";

const CompanySummary = () => {
  return (
    <Box width="100%">
      <Head />
      <Box width="80%" margin="auto" mt={5}>
        <Hidden smUp textalin="center" margin="auto">
          <WrapTypo style={{ writingMode: "vertical-rl" }}>会社概要</WrapTypo>
        </Hidden>
        <Grid container>
          <Grid item xs={3} md={2}>
            <WrapTypo color="#4C4C4C">名称：</WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              所在地：
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              FAX：
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              代表：
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              E-MAIL：
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              URL：
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              登録：
            </WrapTypo>
          </Grid>

          <Grid item xs={9} md={4} style={{ marginBottom: "50px" }}>
            <WrapTypo color="#4C4C4C">株式会社早藤工務店</WrapTypo>
            <WrapTypo color="#4C4C4C" style={{ paddingTop: "29px" }}>
              〒998-0016
              <br />
              山形県酒田市北千日町4-2
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              0234-33-7460
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              早藤 亮
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              //email(空欄)
            </WrapTypo>
            <WrapTypo color="#4C4C4C" lh={5}>
              https://hayahuji-k.jp/office/
            </WrapTypo>
            <WrapTypo color="#4C4C4C" style={{ paddingTop: "30px" }}>
              建設業認可登録
              <br />
              山形県知事許可(般-29)第700637号
              <br />
              二級建築士設計事務所登録
              <br />
              山形県知事登録(2706)第1109号
              <br />
              <br />
              保有資格：二級建築士　第6281号
              <br />
              二級建築士　第9108号
            </WrapTypo>
          </Grid>
          <Grid item xs={12} md={5}>
            <WrapTypo color="#4C4C4C">アクセス</WrapTypo>
            <WrapTypo color="#4C4C4C">
              お車でお越しのお客様　酒田中央インターから20分
            </WrapTypo>
            <WrapTypo color="#4C4C4C">
              電車・徒歩でお越しのお客様　酒田駅から徒歩20分
            </WrapTypo>
            <Hidden smUp>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6874705676437!2d139.8378401153513!3d38.93112027956537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8e9e84f709e427%3A0x2bf60f13973952ba!2zKOagqinml6nol6Tlt6Xli5nlupc!5e0!3m2!1sja!2sjp!4v1619764860494!5m2!1sja!2sjp"
                width="300"
                height="300"
                style={{ border: "none" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Hidden>
            <Hidden xsDown>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3103.6874705676437!2d139.8378401153513!3d38.93112027956537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8e9e84f709e427%3A0x2bf60f13973952ba!2zKOagqinml6nol6Tlt6Xli5nlupc!5e0!3m2!1sja!2sjp!4v1619764860494!5m2!1sja!2sjp"
                width="400"
                height="400"
                style={{ border: "none" }}
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </Hidden>
          </Grid>
          <Grid item md={1}>
            <Hidden xsDown>
              <WrapTypo color="#4C4C4C" style={{ writingMode: "vertical-rl" }}>
                会社概要
              </WrapTypo>
            </Hidden>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CompanySummary;
